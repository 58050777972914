<template>
  <div>
    <b-modal
      id="modal-payment"
      centered
      hide-footer
      size="lg"
      :title="modalTitle"
      class="pb-3"
    >
      <div
        v-if="detail.id !== undefined"
      >
        <b-card-text class="mt-1 mb-75">
          <b-alert
            v-if="errors.length > 0"
            variant="danger"
            show
          >
            <div class="alert-body">
              <p
                v-for="error in errors"
                :key="error"
              >
                {{ error }}
              </p>
            </div>
          </b-alert>
          <b-row>
            <b-col
              md="6"
              cols="12"
            >
              <b-form-group
                label="Tanggal Peminjaman :"
              >
                <b>{{ detail.loan.loan_start }} s/d {{ detail.loan.loan_end }}</b>
              </b-form-group>
              <b-form-group
                label="Tanggal Pengajuan :"
              >
                <b>{{ detail.loan.booking_date }} (oleh {{ detail.loan.requester.name }})</b>
              </b-form-group>
              <b-form-group
                label="Status :"
              >
                <b-badge
                  variant="light-primary"
                  class="mb-75"
                >
                  {{ detail.loan.status }}
                </b-badge>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <b-form-group
                label="Anggota :"
              >
                <b-list-group>
                  <b-list-group-item
                    v-for="(member, index) in detail.loan.members"
                    :key="member.id"
                  >
                    <b>{{ index+1 }}. {{ member.name }} <span v-if="member.nim">({{ member.nim }})</span></b>
                  </b-list-group-item>
                </b-list-group>
              </b-form-group>
              <b-form-group
                v-if="$can('create', 'Loan') && detail.status == 'REQUEST'"
                label="Upload Form Pengajuan :"
              >
                <b-form-file
                  v-model="file"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              cols="12"
            >
              <b-form-group
                v-if="detail.loan.loan_reason"
                label="Tujuan Peminjaman :"
              >
                <b>{{ detail.loan.loan_reason }}</b>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              cols="12"
            >
              <b-form-group
                v-if="detail.loan.description"
                label="Deskripsi :"
              >
                <b>{{ detail.loan.description }}</b>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="12"
              cols="12"
            >
              <b-form-group
                label="List Inventaris :"
              >
                <b-table
                  class="position-relative"
                  :items="detail.loan.inventories"
                  small="true"
                  :fields="inventoryColumns"
                  primary-key="id"
                  show-empty
                  empty-text="No matching records found"
                >
                  <template #cell(no)="data">
                    {{ data.index + 1 }}
                  </template>
                </b-table>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
        <b-card-text class="mt-1 mb-75">
          <b-row>
            <b-col
              md="6"
              cols="6"
            >
              <b-form-group
                label="Total Denda :"
              >
                <b-form-input
                  v-model="detail.total_debt"
                  disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              cols="6"
            >
              <b-form-group
                label="Pembayaran Denda :"
              >
                <b-form-input
                  v-model="detail.payment_amount"
                  placeholder="Enter Payment Amount"
                  type="number"
                  :disabled="detail.status == 'done'"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
        <div
          class="d-flex align-items-center justify-content-end mb-1"
        >
          <b-button
            v-if="$can('read', 'Debt') && detail.status == 'unpaid'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            :disabled="detail.form_path == ''"
            @click="handlePayment('approved')"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            <span>Kirim</span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, VBModal, BBadge, BCardText, BRow, BCol, BFormGroup, BButton, BListGroup, BListGroupItem, BFormFile, BAlert, BTable, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BBadge,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BListGroup,
    BListGroupItem,
    BFormFile,
    BAlert,
    BTable,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Pembayaran Denda',
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
    verify: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      file: null,
      inventoryColumns: [
        {
          key: 'no',
        },
        {
          key: 'name',
          label: 'Nama',
        },
        {
          key: 'inventory_code',
          label: 'Nomor Inventaris',
        },
        {
          key: 'condition',
          label: 'Kondisi',
        },
        {
          key: 'debt',
          label: 'Denda',
        },
      ],
    }
  },
  methods: {
    handlePayment() {
      this.$http.put(`/v1/debt/${this.detail.id}`, this.detail)
        .then(() => {
          this.$bvModal.hide('modal-payment')
          this.$emit('success')
          this.$emit('refresh')
        })
        .catch(error => {
          this.errors = error.response.data.errors
          this.$emit('error', error.response.data.messages)
        })
    },
  },
}
</script>
