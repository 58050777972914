<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            List Denda
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay
        :show="overlay"
        rounded="sm"
      >
        <b-table
          class="position-relative"
          :items="fetchData"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(no)="data">
            {{ (fetchMeta.current_page - 1) * fetchMeta.per_page + data.index + 1 }}
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-b-modal.modal-payment
                @click="payment(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Payment</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ fetchMeta.current_page }} from {{ fetchMeta.last_page }} Pages</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="fetchMeta.total"
                :per-page="page.size"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <ModalPayment
      :detail="formData"
      @refresh="getDebtList()"
      @success="successToast()"
      @error="errorToast($event)"
    />
  </div>
</template>
<script>
import {
  BCard, BOverlay, BRow, BCol, BFormInput, BTable, BPagination, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import ModalPayment from './ModalPayment.vue'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,

    ModalPayment,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      overlay: true,
      currentPage: 1,
      searchQuery: '',
      fetchData: [],
      fetchMeta: {},
      tableColumns: [
        { key: 'no' },
        { key: 'user.name', label: 'Peminjam' },
        { key: 'debt_date', label: 'Tanggal Denda' },
        { key: 'total_debt', label: 'Nominal Denda' },
        { key: 'payment_date', label: 'Tanggal Pembayaran' },
        { key: 'payment_amount_formatted', label: 'Nominal Pembayaran' },
        { key: 'actions' },
      ],
      page: {
        size: 10,
        number: 1,
      },
      filter: {
        search: '',
      },
      modalTitle: '',
      formData: {},
      parentCategoryList: [],
    }
  },
  watch: {
    currentPage() {
      this.page.number = this.currentPage
      this.getDebtList()
    },
    searchQuery() {
      this.filter.search = this.searchQuery
      this.getDebtList()
    },
  },
  mounted() {
    this.getDebtList()
  },
  methods: {
    getDebtList() {
      const data = {
        params: {
          'page[number]': this.page.number,
          'page[size]': this.page.size,
        },
      }

      this.$http.get('/v1/debt/list', data)
        .then(response => {
          this.setFetchData(response.data.data)
          this.setFetchMeta(response.data.meta)
          this.overlay = false
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    getParentCategoryList() {
      const data = {
        params: {
          'page[size]': 0,
        },
      }

      this.$http.get('/v1/category/list', data)
        .then(response => {
          this.parentCategoryList = response.data.data.map(item => ({
            value: item.id,
            text: item.name,
          }))

          this.parentCategoryList.unshift({
            value: null,
            text: 'Select Parent Category',
          })
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    setFetchData(data) {
      this.fetchData = data
    },
    setFetchMeta(data) {
      this.fetchMeta = data
    },
    handleCreate() {
      this.getParentCategoryList()
      this.modalTitle = 'Tambah Kategori'
      this.formData = {
        parent_id: null,
      }
    },
    payment(data) {
      this.formData = data
    },
    successToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Berhasil',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    errorToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>
